<template>
    <div :class="'background-img-'+ index +' ' + config.className">
        <i 
            class="fas fa-chevron-left"  
            @click="index--"
        />
        <component
            :is="getPanel(n).modname.value" 
            :config="getPanel(n).config"
            v-for="n in parsedNumber"
            :key="'panel-index-'+(n + index)"
        />
        <i 
            class="fas fa-chevron-right"  
            @click="index++"
        />
    </div>
</template>

<script>

    import modules from '@/store/module-import.js'

    export default {
        props:['config'],
        data(){
            return {
                index: 0
            }
        },
        methods:{
            getPanel(n){
                let i = (this.index + (n-1)) % this.config.panels.length;
                return this.config.panels[i];
            }
        },
        computed:{
            currentPanel(){
                return this.config.panels[this.index]
            },
            parsedNumber(){
                return parseInt(this.config.displayAmount);
            }
        },
        watch:{
            index(){
                if(this.index < 0){
                    this.index = this.config.panels.length - 1;
                }else if(this.index >= this.config.panels.length){
                    this.index = 0
                }
            }
        },
        components: {
            ...modules
        },
        mounted(){
            if(!this.config.className){
                this.config.className = ""
            }

            if(!this.config.displayAmount || isNaN(this.config.displayAmount) || this.config.displayAmount <= 0){
                this.config.displayAmount = 1;
            }

            if(this.config.onChange && this.config.onChange != ""){
                window.addEventListener(this.config.onChange,(e) => {
                    this.index = e.detail;
                });
            }

            if(this.config.onIncr && this.config.onIncr != ""){
                window.addEventListener(this.config.onIncr,() => {
                    this.index++;
                });
            }

            if(this.config.onDecr && this.config.onDecr != ""){
                window.addEventListener(this.config.onDecr,() => {
                    this.index--;
                });
            }
        }
    }
</script>
